<!--  -->
<template>
  <div class="bod">
    <Breadcrumb style="margin-top: 20px;margin-left: 163px;margin-bottom: 20px;color: rgb(110, 114, 117);">
        <BreadcrumbItem to="/">首页</BreadcrumbItem>
        <BreadcrumbItem to="/message">相关资讯</BreadcrumbItem>
        <BreadcrumbItem>详情</BreadcrumbItem>
    </Breadcrumb>
    <div class="flex">
      <div style="width: 650px;" class="biaoti">
        巾帼妈妈灵活创业外展就业招聘服务活动
      </div>
    </div>
    <div class="flex">
    <div class="xgleft3"></div>
      <div class="xgleft4">就业创业</div>
      <div class="xgleft1">发布时间：&nbsp&nbsp2023.07.01</div>
    </div>
    <div class="flex">
      <div class="wenben1">
        近日，朝九晚四为了让巾帼妈妈了解招聘，提升巾帼妈妈的招聘能力，中心链接招聘会资源让妈妈开展外展就业招聘服务，希望妈妈能从实践中提升技能，拓展个人招聘资源，促进就业岗位对接实现灵活增收。
      </div>
    </div>
    <div class="flex">
      <div class="page2tu1"></div>
    </div>
    <div class="flex">
      <div style="margin-bottom: 50px;" class="wenben2">        现场，巾帼妈妈积极、贴心的为前来咨询的就业人员介绍，并积极指导他们注册招工平台，希望能让他们实现就业。其中有位妈妈在多次介绍不成功时主动观察其他企业介绍，学习相关沟通技巧，希望就此提升个人招聘能力。做中学，学中做，这是我们妈妈们具有的良好品质。</div>
    </div>
    <div class="flex">
      <div class="page2tu2"></div>
    </div>
    <div class="flex">
      <div style="margin-left: 250px;" class="wenben3">                活动结束后朝九晚四工作人员与妈妈一起总结本次招聘情况及有待提升之处，希望大家能不断进步，成就他人，提升自己。</div>
    </div>
   
    <div class="flex">
      <hr class="hr1"/>
    </div>
    <div style="margin-left: 163px;float: left;">
      <div class="next">上一篇</div>
      <div class="nextkuang">
        <div class="xgleft1">2023.07.11</div>
        <router-link to="/page1" class="text1">黄埔区妇联、人社局联合调研朝九晚四巾帼创客中心</router-link>
        <div class="xgleft3"></div>
        <div class="xgleft4">就业创业</div>
        <router-link to="/page1" class="text2">7月10日上午，黄埔区妇联主席王晓虹、人社局副局长黄智祥、区妇联副主席宋赫男、区就业管理中心主任綦宗彬、就业处王晨等领导一行，就省人社厅、省妇联发布的《关于推行“妈妈岗”模式，促进妇女就业的实施意见》的征求意见稿和朝九晚四巾帼创客中心近年的运营情况......</router-link>
        <router-link to="/page1"><div class="text3">查看详情<img src="../../../assets/images/you.png"></div></router-link>
      </div>
    </div>
    <div style="margin-left: 75px;float: left;">
      <div class="next">下一篇</div>
      <div class="nextkuang">
        <div class="xgleft1">2023.05.17</div>
        <router-link to="/page3" class="text1">【区创投项目】促进就业，宝妈创业”线下宣传招募活动</router-link>
        <div class="xgleft3"></div>
        <div class="xgleft4">就业创业</div>
        <router-link to="/page3" class="text2">让更多妈妈了解朝九晚四“促进就业，宝妈创业”巾帼妈妈灵活创业互助项目，让有意愿灵活创业增收的妈妈有渠道实现自己的想法，助力妈妈步入社会，提升自我。5月16日，朝九晚四巾帼创客中心工作人员在新东党建文化广场开展宣传活动......</router-link>
        <router-link to="/page3"><div class="text3">查看详情<img src="../../../assets/images/you.png"></div></router-link>
      </div>
    </div>
    <div class="clean"></div>
  </div>

</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {

  },
  created() {

  }
}
</script>

<style lang="less" scoped>
@import "./page.less";
</style>
